<template>
    <div class="container">
        <div class="inputList" style="padding-top: 50px !important">
            <div class="company">In House Processes</div>
            <el-checkbox-group v-model="checkList3" :disabled="disabled">
                <el-checkbox
                    :label="item.id"
                    v-for="(item, index) in house_processesList"
                    :key="index"
                    @change="changeCheckbox($event, item)"
                >
                    {{ item.dictName }}
                </el-checkbox>
            </el-checkbox-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "Processesn",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checkList3: [],
            house_processesList: [],
        };
    },
    created() {
        if (this.list) {
            this.checkList3 = [
                ...new Set(this.list.map((items) => items.dictId)),
            ];
        }
        this.house_processesList = JSON.parse(
            localStorage.getItem("in_house_processes")
        );
    },
    methods: {
        changeCheckbox() {
            console.log(this.checkList3);
            let list = [];
            this.house_processesList.forEach((e) => {
                if (this.checkList3.includes(e.id)) {
                    list.push({
                        dictId: e.id,
                        dictName: e.dictName,
                    });
                }
            });
            this.$emit("change", list);
        },
    },
};
</script>

<style></style>
