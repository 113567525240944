<template>
    <div class="container">
        <div class="inputList">
            <div class="company">Quality Management Systems</div>
            <div class="mb-2" v-for="(item, i) in qualityList" :key="i">
                <div class="subject text-normal">
                    {{ item.dictName }}
                    <span class="red-text">*</span>
                </div>
                <div>
                    <el-radio-group
                        v-model="qlist[i].qualitySelect"
                        @change="changeValue"
                        :disabled="disabled"
                    >
                        <el-radio label="Y">Y</el-radio>
                        <el-radio label="N" class="ml-10">N</el-radio>
                    </el-radio-group>
                </div>
                <div class="textarea" style="margin-top: 10px">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder=""
                        v-model="qlist[i].qualitySelectAnswer"
                        @change="changeValue"
                        :disabled="disabled"
                    ></el-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoFile",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            qualityList: [],
            qlist: [],
        };
    },
    created() {
        this.qualityList = JSON.parse(
            localStorage.getItem("quality_management_systems")
        );
        if (this.list && this.list.length > 0) {
            this.qlist = this.list;
        } else {
            this.qualityList.forEach((os) => {
                this.qlist.push({
                    qualityContent: os.dictName,
                    qualityId: os.id,
                    qualitySelect: "",
                    qualitySelectAnswer: "",
                });
            });
        }
    },
    methods: {
        changeValue() {
            // item.IsCheck = e;
            this.$emit("change", this.qlist);
        },
    },
};
</script>

<style>
.el-textarea textarea {
    resize: none;
}
</style>
