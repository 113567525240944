<template>
    <div class="container">
        <Tabs
            :curTab="curTab"
            :list="tabLen"
            @change="change"
            :showAdd="false"
            v-if="!disabled"
        ></Tabs>

        <div v-for="(step, ids) in dataList" :key="ids">
            <div v-show="curTab == ids">
                <!-- Organisation Structure -->
                <div class="inputList">
                    <div class="company">Organisation Structure</div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Total number of employees
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.orgStructure.employeesTotalNumber"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Number of QA
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.orgStructure.qaNumber"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Number of QC
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.orgStructure.qcNumber"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Number of Engineering</div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.orgStructure.engineerNumber"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Number of R&D</div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.orgStructure.rdNumber"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                </div>
                <!-- Facility -->
                <div class="inputList">
                    <div class="company">Facility</div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Total factory size</div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.facility.totalFactorySize"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Canteen</div>
                        <div class="select">
                            <el-select
                                v-model="step.facility.canteen"
                                placeholder="Please select"
                                style="width: 544px"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in yn_dictList"
                                    :key="item.dictName"
                                    :label="item.dictName"
                                    :value="item.dictName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Dormitory
                            <span class="red-text">*</span>
                        </div>
                        <div class="select">
                            <el-select
                                v-model="step.facility.dormitory"
                                placeholder="Please select"
                                style="width: 544px"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in yn_dictList"
                                    :key="item.dictName"
                                    :label="item.dictName"
                                    :value="item.dictName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Sample Room
                            <span class="red-text">*</span>
                        </div>
                        <div class="select">
                            <el-select
                                v-model="step.facility.sampleRoom"
                                placeholder="Please select"
                                style="width: 544px"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in yn_dictList"
                                    :key="item.dictName"
                                    :label="item.dictName"
                                    :value="item.dictName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Tooling Workshop</div>
                        <div class="select">
                            <el-select
                                v-model="step.facility.toolingWorkshop"
                                placeholder="Please select"
                                style="width: 544px"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in yn_dictList"
                                    :key="item.dictName"
                                    :label="item.dictName"
                                    :value="item.dictName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Laboratory
                            <span class="red-text">*</span>
                        </div>
                        <div class="select">
                            <el-select
                                v-model="step.facility.laboratory"
                                placeholder="Please select"
                                style="width: 544px"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in yn_dictList"
                                    :key="item.dictName"
                                    :label="item.dictName"
                                    :value="item.dictName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div
                        class="flex-between mb-2"
                        v-if="step.facility.laboratory == 'Y'"
                    >
                        <div class="text-normal"></div>
                        <div class="select" style="height: 70px">
                            <el-checkbox-group
                                v-model="checkList[ids].list"
                                @change="laboratorySelect($event, step, 1)"
                                :disabled="disabled"
                            >
                                <el-checkbox
                                    :label="item.dictName"
                                    v-for="(item, i) in laboratoryList"
                                    :key="i"
                                    style="margin-top: 10px"
                                ></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div
                        class="flex-between mb-2"
                        v-if="step.facility.laboratory == 'Y'"
                    >
                        <div class="text-normal"></div>
                        <div class="select flex-between">
                            <div class="text-normal" style="line-height: 40px">
                                Test Lab Accreditation
                            </div>
                            <div class="input" style="width: 329px">
                                <el-input
                                    placeholder=""
                                    v-model="step.facility.testLabAccreditation"
                                    :disabled="disabled"
                                ></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Design Capability</div>
                        <div class="select">
                            <el-checkbox-group
                                v-model="checkList1[ids].list"
                                class="mt-1"
                                @change="laboratorySelect($event, step, 2)"
                                :disabled="disabled"
                            >
                                <el-checkbox
                                    :label="item.dictName"
                                    v-for="(item, i) in designList"
                                    :key="i"
                                >
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <!-- Quality Management Systems -->
                <Quality
                    :list="step.qualityMgrSys"
                    :disabled="disabled"
                    @change="changeQuality($event, step)"
                ></Quality>
                <!-- In House Processesn -->
                <Processesn
                    :list="step.houseProcessesList"
                    :disabled="disabled"
                    @change="changeProcessesn($event, step)"
                ></Processesn>
                <!-- Operating Capacity & Lead Times -->
                <div class="inputList">
                    <div class="company">Operating Capacity & Lead Times</div>
                    <div class="mb-2">
                        <div class="subject text-normal">
                            Overall, the factory operates at what level of
                            capacity
                            <span class="red-text">*</span>
                        </div>
                        <div class="radio-group">
                            <el-radio-group
                                v-model="radioList[ids].radio"
                                :disabled="disabled"
                            >
                                <el-radio
                                    :label="item.id"
                                    v-for="item in capacity_level"
                                    :key="item.id"
                                    @change="changeRadios($event, item, step)"
                                    >{{ item.dictName }}</el-radio
                                >
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="subject text-normal">
                            Peak Season Information
                        </div>
                        <div style="width: 100%">
                            <div class="flex-center itemTable monthsType">
                                <div>Month</div>
                                <div v-for="(item, t) in monthName" :key="t">
                                    {{ item }}
                                </div>
                            </div>
                            <div class="flex-center itemTable monthsType">
                                <div>{{ step.peakSeasonInfo.alias }}</div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.jan"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.feb"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.march"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.april"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.may"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.jun"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.july"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.august"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.seq"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.octName"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.nov"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.peakSeasonInfo.decName"
                                        :disabled="disabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="subject text-normal">
                            Peak Season Information
                        </div>
                        <div style="width: 520px">
                            <div class="flex-center itemTable">
                                <div v-for="(item, t) in InfoName" :key="t">
                                    {{ item }}
                                </div>
                            </div>
                            <div class="flex-center itemTable">
                                <div>{{ step.leadtime.alias }}</div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.leadtime.standard"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.leadtime.peakSeason"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="step.leadtime.npd"
                                        :disabled="disabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Attachment  -->
                <Attachments
                    :element="`setp22${ids}`"
                    :list="
                        disabled
                            ? [
                                  ...step.webFactoryAttachment,
                                  ...step1List[ids].attachmentList,
                              ]
                            : step.webFactoryAttachment
                    "
                    :disabled="disabled"
                    @dropEvent="dropEvent($event, step)"
                    @dropDel="dropDel($event, step)"
                ></Attachments>
                <!-- Vendor Declaration -->
                <div class="inputList">
                    <div class="company">Vendor Declaration</div>
                    <div class="subject text-normal">
                        We confirm that all information contained in this
                        submission is accurate and complete.
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Completed By
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.vendorDeclaration.complatedBy"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Position
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.vendorDeclaration.position"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Date
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                placeholder=""
                                v-model="step.vendorDeclaration.date"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="submit" @click="Submit" v-if="!disabled && dataStatus != 3">
            Submit
        </div>
    </div>
</template>

<script>
import Tabs from "./Tabs.vue";
import Quality from "./Quality.vue";
import Processesn from "./Processesn.vue";
import Attachments from "./Attachments.vue";

// import data3 from "../common/data3.js";
export default {
    name: "Step2",
    components: {
        Tabs,
        Quality,
        Attachments,
        Processesn,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        activeIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            curTab: 0,
            dataList: [],
            tabLen: [],

            // Tyep
            yn_dictList: [],
            laboratoryList: [],
            designList: [],
            capacity_level: [],

            // Operating Capacity & Lead Times
            radio: null,
            monthName: [
                "Jan",
                "Feb",
                "March",
                "April",
                "May",
                "Jun",
                "July",
                "August",
                "Sep",
                "Oct.",
                "Nov.",
                "Dec",
            ],
            InfoName: ["", "Standard", "Peak Season", "NPD"],

            checkList: [],
            checkList1: [],
            radioList: [],
            step1List: [],
            qualityList: [],
            request: false,
            dataStatus: 3,
            rules: {
                "orgStructure.employeesTotalNumber": [
                    {
                        require: true,
                        message: "Total number of employees can not be empty",
                    },
                ],
                "orgStructure.qaNumber": [
                    {
                        require: true,
                        message: "Number of QA can not be empty",
                    },
                ],
                "orgStructure.qcNumber": [
                    {
                        require: true,
                        message: "Number of QC can not be empty",
                    },
                ],
                "facility.dormitory": [
                    {
                        require: true,
                        message: "Dormitory can not be empty",
                    },
                ],
                "facility.sampleRoom": [
                    {
                        require: true,
                        message: "Sample Room can not be empty",
                    },
                ],
                "facility.laboratory": [
                    {
                        require: true,
                        message: "Laboratory can not be empty",
                    },
                ],
                "vendorDeclaration.complatedBy": [
                    {
                        require: true,
                        message: "Completed By can not be empty",
                    },
                ],
                "vendorDeclaration.position": [
                    {
                        require: true,
                        message: "Position can not be empty",
                    },
                ],
                "vendorDeclaration.date": [
                    {
                        require: true,
                        message: "Date can not be empty",
                    },
                ],
            },
            qualityMgrSysRules: {
                qualitySelect: [
                    {
                        require: true,
                        message: "Quality Management Systems can not be empty",
                    },
                ],
                // qualitySelectAnswer: [
                //     {
                //         require: true,
                //         message: "Quality Management Systems can not be empty",
                //     },
                // ],
            },
            radioRules: {
                radio: [
                    {
                        require: true,
                        message:
                            "Overall, the factory operates at what level of capacity can not be empty",
                    },
                ],
            },
        };
    },
    watch: {
        activeIndex(val) {
            this.curTab = val;
        },
    },
    created() {
        let data = JSON.parse(localStorage.getItem("dataList"));
        console.log(data);
        this.yn_dictList = JSON.parse(localStorage.getItem("yn_dict"));
        this.laboratoryList = JSON.parse(localStorage.getItem("laboratory"));
        this.designList = JSON.parse(localStorage.getItem("design_capability"));
        this.capacity_level = JSON.parse(
            localStorage.getItem("capacity_level")
        );
        this.qualityList = JSON.parse(
            localStorage.getItem("quality_management_systems")
        );
        // let dictName = this.yn_dictList[0].dictName;

        this.company = data.stepOneParam.company;

        this.dataStatus = this.company.dataStatus;

        this.step1List = data.stepOneParam.factoryList;

        let list = data.stepTwoParam.factoryList;

        this.dataList = list;
        list.forEach((os) => {
            this.tabLen.push({
                name: os.factoryName,
            });
            // const params = {
            //   ...JSON.parse(JSON.stringify(data3)),
            //   factoryId: os.factoryId,
            //   factoryName: os.factoryName,
            // };
            // this.dataList.push(params);
        });

        this.dataList.forEach((os) => {
            //   console.log(os);
            os.peakSeasonInfo.alias = "Operating Capacity";
            os.leadtime.alias = "Lead Time";
            // os.facility.canteen = dictName;
            // os.facility.dormitory = dictName;
            // os.facility.sampleRoom = dictName;
            // os.facility.toolingWorkshop = dictName;
            // os.facility.laboratory = dictName;
            // list.forEach((vs) => {
            //   os.factoryId = vs.factoryId;
            //   os.factoryName = vs.factoryName;
            // });
            this.checkList.push({
                list: os.facility
                    ? os.facility.laboratorySelect.split(",")
                    : [],
            });
            this.checkList1.push({
                list: os.facility
                    ? os.facility.designCapability.split(",")
                    : [],
            });
            this.radioList.push({
                radio:
                    os.capacityLevelList && os.capacityLevelList.length > 0
                        ? os.capacityLevelList[os.capacityLevelList.length - 1]
                              .dictId
                        : null,
            });
        });
    },
    methods: {
        change(e) {
            this.curTab = e;
        },
        laboratorySelect(val, step, type) {
            if (type == 1) {
                step.facility.laboratorySelect = String(val);
            } else if (type == 2) {
                step.facility.designCapability = String(val);
            }
        },
        dropEvent(val, step) {
            step.webFactoryAttachment.push(val);
        },
        dropDel(index, step) {
            step.webFactoryAttachment.splice(index, 1);
        },
        changeRadios(e, item, step) {
            let items = {
                dictId: item.id,
                dictName: item.dictName,
            };
            step.capacityLevelList[0] = items;
        },
        changeQuality(e, step) {
            step.qualityMgrSys = e;
        },
        changeProcessesn(e, step) {
            step.houseProcessesList = e;
        },
        validate(rules, params) {
            const keys = Object.keys(rules);
            for (let i = 0; i < keys.length; i++) {
                const [key, key1, key2] = keys[i].split(".");

                const length = keys[i].split(".").length;

                let result;
                switch (length) {
                    case 1:
                        result = params[key];
                        break;
                    case 2:
                        if (Reflect.has(params, key)) {
                            result = params[key][key1];
                        }
                        break;
                    case 3:
                        if (
                            Reflect.has(params, key) &&
                            Reflect.has(params[key], key1)
                        ) {
                            result = params[key][key1][key2];
                        }
                        break;
                }

                const ruless = rules[keys[i]];

                for (let j = 0; j < ruless.length; j++) {
                    const rule = ruless[j];

                    if (rule.require && !result) {
                        this.$message({
                            message: rule.message,
                            type: "error",
                        });
                        return false;
                    }
                }
            }
            return true;
        },
        Submit() {
            if (this.request) return;

            let dataList = this.$u.filterId(
                this.dataList,
                "webFactoryAttachment"
            );

            const date = new Date();

            const year = date.getFullYear();

            let month = date.getMonth() + 1;

            month = month >= 10 ? month : `0${month}`;

            let day = date.getDate();

            day = day >= 10 ? day : `0${day}`;

            dataList = dataList.map((items) => {
                items.vendorDeclaration.date = `${day}/${month}/${year}`;
                return items;
            });

            let parmas = {
                factoryList: dataList,
                webCompanyId: this.company.id,
            };

            for (let i = 0; i < dataList.length; i++) {
                const temp = dataList[i];

                if (!this.validate(this.rules, temp)) return;

                for (let j = 0; j < this.qualityList.length; j++) {
                    const item = temp.qualityMgrSys[j];
                    if (!item) {
                        this.$message.error(
                            "Quality Management Systems can not be empty"
                        );
                        return;
                    }
                    if (!this.validate(this.qualityMgrSysRules, item)) return;
                }

                if (!this.validate(this.radioRules, this.radioList[i])) return;
            }

            this.request = true;

            this.$http
                .submitStep2(parmas)
                .then((res) => {
                    this.request = false;
                    if (res.code == 200) {
                        console.log(res);
                        const data = JSON.parse(
                            localStorage.getItem("dataList")
                        );

                        data.stepTwoParam.factoryList = dataList;

                        data.stepOneParam.company.dataStatus = 3;

                        localStorage.setItem("dataList", JSON.stringify(data));

                        this.dataStatus = 3;

                        this.$emit("choose", res.data);
                        this.$message({
                            message: res.data,
                            type: "success",
                        });
                    }
                })
                .catch(() => {
                    this.request = false;
                });
        },
    },
};
</script>

<style>
.container {
}
</style>
