<template>
    <div id="" class="container">
        <!-- Company -->
        <div>
            <!-- Company Information-->
            <div class="inputList">
                <div class="company">Company Information</div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Company Name <span class="red-text">*</span>
                    </div>
                    <div class="input">
                        <el-input
                            v-model="company.companyName"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Business Address <span class="red-text">*</span>
                    </div>
                    <div
                        class="select flex-start"
                        style="flex-shrink: 1 !important"
                    >
                        <Address
                            :data="{
                                Country: company.businessAddressCountry,
                                City: company.businessAddressCity,
                                Detail: company.businessAddressState,
                            }"
                            :disabled="disabled"
                            @change="changeAddress($event, 'Company', company)"
                        ></Address>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal"></div>
                    <div class="textarea">
                        <el-input
                            type="textarea"
                            :rows="3"
                            v-model="company.businessAddressDetail"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">Post Code</div>
                    <div class="input">
                        <el-input
                            v-model="company.postCode"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">Tel No.</div>
                    <div class="input">
                        <el-input
                            v-model="company.telNo"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">Website</div>
                    <div class="input">
                        <el-input
                            v-model="company.website"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Business License No.
                        <span class="red-text">*</span>
                    </div>
                    <div class="input">
                        <el-input
                            v-model="company.businessLicenseNo"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Business Type
                        <span class="red-text">*</span>
                    </div>
                    <div class="select">
                        <el-select
                            v-model="company.businessType"
                            style="width: 544px"
                            :disabled="disabled"
                        >
                            <el-option
                                v-for="item in businessList"
                                :key="item.dictName"
                                :label="item.dictName"
                                :value="item.dictName"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Date Established
                        <span class="red-text">*</span>
                    </div>
                    <div class="select">
                        <el-date-picker
                            v-model="company.dateEstablished"
                            type="date"
                            format="dd/MM/yyyy"
                            value-format="dd/MM/yyyy"
                            style="width: 100%"
                            :disabled="disabled"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Ownership Structure
                        <span class="red-text">*</span>
                    </div>
                    <div class="select">
                        <el-checkbox-group
                            v-model="company.ownershipStructure"
                            class="mt-1"
                            :disabled="disabled"
                        >
                            <el-checkbox
                                :label="item.dictName"
                                v-for="(item, i) in ownershipList"
                                :key="i"
                            >
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="flex-between mb-2" v-if="company.businessScaleList">
                    <div class="text-normal">Business Scale</div>
                    <div class="table">
                        <div class="flex-center itemTable">
                            <div v-for="(item, t) in yearNmae" :key="t">
                                {{ item }}
                            </div>
                        </div>
                        <div
                            class="flex-center itemTable"
                            v-for="(item, i) in company.businessScaleList"
                            :key="i"
                        >
                            <div>{{ item.aliasName }}</div>
                            <div>
                                <input
                                    type="text"
                                    v-model="item.thisYear"
                                    :disabled="disabled"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    v-model="item.lastYear"
                                    :disabled="disabled"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    v-model="item.previousYear"
                                    :disabled="disabled"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">Company Info</div>
                    <div class="textarea">
                        <el-input
                            type="textarea"
                            :rows="3"
                            v-model="company.companyInfo"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
            </div>
            <!-- Key Contact -->
            <div class="inputList" v-if="company.contact">
                <div class="company">Key Contact</div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Contact Person
                        <span class="red-text">*</span>
                    </div>
                    <div class="select flex-start">
                        <el-select
                            v-model="company.contact.contactSex"
                            :disabled="disabled"
                        >
                            <el-option
                                v-for="item in sexList"
                                :key="item.dictName"
                                :label="item.dictCode"
                                :value="item.dictName"
                            >
                            </el-option>
                        </el-select>
                        <div
                            class="input"
                            style="width: 350px; margin-left: 16px"
                        >
                            <el-input
                                v-model="company.contact.contactName"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Position
                        <span class="red-text">*</span>
                    </div>
                    <div class="input">
                        <el-input
                            v-model="company.contact.position"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Email Address
                        <span class="red-text">*</span>
                    </div>
                    <div class="input">
                        <el-input
                            disabled
                            v-model="company.contact.emailAddress"
                        ></el-input>
                    </div>
                </div>
                <div class="flex-between mb-2">
                    <div class="text-normal">
                        Mobile Number
                        <span class="red-text">*</span>
                    </div>
                    <div class="input">
                        <el-input
                            v-model="company.contact.mobileNumber"
                            :disabled="disabled"
                        ></el-input>
                    </div>
                </div>
            </div>
            <!--Attachment  -->
            <Attachments
                v-if="company.attachmentList"
                element="step1"
                :disabled="disabled"
                :list="company.attachmentList"
                @dropEvent="dropEvent($event, company)"
                @dropDel="dropDel($event, company)"
            ></Attachments>
        </div>

        <div class="factory">Factory</div>
        <Tabs
            :curTab="curTab"
            :list="tabList"
            :showAdd="true"
            :disabled="disabled"
            @change="change"
            @changeTab="changeTab"
        ></Tabs>

        <div v-for="(step, ids) in dataList" :key="ids">
            <div v-show="curTab == ids">
                <!-- Factory Information -->
                <div class="inputList">
                    <div class="company">Factory Information</div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Factory Name
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                v-model="step.factoryName"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Factory Address
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                v-model="step.factoryAddress"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Tel No.
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                v-model="step.telNo"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Email
                            <span class="red-text">*</span>
                        </div>
                        <div class="input">
                            <el-input
                                v-model="step.email"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Date Established
                            <span class="red-text">*</span>
                        </div>
                        <div class="select">
                            <el-date-picker
                                v-model="step.dateEstablished"
                                type="date"
                                format="dd/MM/yyyy"
                                value-format="dd/MM/yyyy"
                                style="width: 100%"
                                :disabled="disabled"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Ownership Structure</div>
                        <div class="select">
                            <el-checkbox-group
                                v-model="step.ownershipStructure"
                                class="mt-1"
                                :disabled="disabled"
                            >
                                <el-checkbox
                                    :label="item.dictName"
                                    v-for="(item, i) in ownershipList"
                                    :key="i"
                                >
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Loading Port Location
                            <span class="red-text">*</span>
                        </div>
                        <div class="select flex-start">
                            <Address
                                :data="{
                                    Country: step.loadingPortLocationCountry,
                                    City: step.loadingPortLocationCity,
                                    Detail: step.loadingPortLocationArea,
                                }"
                                :disabled="disabled"
                                @change="changeAddress($event, 'Factory', step)"
                            ></Address>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            Export Port
                            <span class="red-text">*</span>
                        </div>
                        <div class="select">
                            <el-select
                                v-model="step.exportPort"
                                style="width: 544px"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in exportList"
                                    :key="item.dictName"
                                    :label="item.dictName"
                                    :value="item.dictName"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">
                            CSR
                            <span class="red-text">*</span>
                        </div>
                        <div class="select">
                            <el-radio-group
                                v-model="step.csr"
                                class="mt-1"
                                :disabled="disabled"
                            >
                                <el-radio label="Y">Y</el-radio>
                                <el-radio label="N">N</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <!-- Product Scope -->
                <Scope
                    :list="step.productScopeList"
                    :disabled="disabled"
                    @change="changeScope($event, step)"
                ></Scope>
                <!-- Key Market & Customers -->
                <Market
                    :list="step.keymarketCustomerList"
                    :disabled="disabled"
                    @change="changeMarket($event, step)"
                ></Market>
                <!-- Factory Information -->
                <InfoFile
                    :list="step.accreditationList"
                    :disabled="disabled"
                    @change="changeInfoFile($event, step)"
                ></InfoFile>
                <!-- Business Scale -->
                <!-- <div class="inputList">
          <div class="company">Business Scale</div>
          <div class="flex-between mb-2">
            <div class="text-normal">Business Scale</div>
            <div class="table">
              <div class="flex-center itemTable">
                <div v-for="(item, t) in yearNmae" :key="t">{{ item }}</div>
              </div>
              <div
                class="flex-center itemTable"
                v-for="(item, i) in step.businessScaleList"
                :key="i"
              >
                <div>{{ item.aliasName }}</div>
                <div>{{ item.thisYear }}</div>
                <div>{{ item.lastYear }}</div>
                <div>{{ item.previousYear }}</div>
              </div>
            </div>
          </div>
        </div> -->
                <div class="inputList">
                    <div class="company">Business Scale</div>
                    <div class="flex-between mb-2">
                        <div class="text-normal">Business Scale</div>
                        <div class="table">
                            <div class="flex-center itemTable">
                                <div v-for="(item, t) in yearNmae" :key="t">
                                    {{ item }}
                                </div>
                            </div>
                            <div
                                class="flex-center itemTable"
                                v-for="(item, i) in step.businessScaleList"
                                :key="i"
                            >
                                <div>{{ item.aliasName }}</div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="item.thisYear"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="item.lastYear"
                                        :disabled="disabled"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        v-model="item.previousYear"
                                        :disabled="disabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Attachment  -->
                <Attachments
                    v-if="!disabled || isNeedStepTwo == 0"
                    :element="`step2${ids}`"
                    :list="step.attachmentList"
                    :disabled="disabled"
                    @dropEvent="dropEvent($event, step)"
                    @dropDel="dropDel($event, step)"
                ></Attachments>
            </div>
        </div>
        <div class="submit" @click="Submit" v-if="!disabled && dataStatus != 3">
            Submit
        </div>
    </div>
</template>

<script>
import Tabs from "./Tabs.vue";
import Market from "./Market.vue";
import Scope from "./Scope.vue";
import InfoFile from "./InfoFile.vue";
import Attachments from "./Attachments.vue";
import Address from "./Address.vue";

import data1 from "../common/data1.js";
import data2 from "../common/data2.js";
export default {
    name: "Step1",
    components: {
        Attachments,
        Tabs,
        Market,
        Scope,
        InfoFile,
        Address,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        activeIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            test: "",
            curTab: 0,
            tabLen: [],

            // **********
            dataList: [],
            company: {
                ...data2,
            },
            businessList: [],
            ownershipList: [],
            sexList: [],
            exportList: [],
            checkList2: [],
            // Company Information
            yearNmae: ["", "This Year", "Last Year", "Previous Year"],
            isNeedStepTwo: 0,
            request: false,
            dataStatus: null,
            rules: {
                companyName: [
                    {
                        require: true,
                        message: "Company Name can not be empty",
                    },
                ],
                businessAddressCity: [
                    {
                        require: true,
                        message: "Business Address can not be empty",
                    },
                ],
                // businessAddressCountry: [
                //     {
                //         require: true,
                //         message: "Business Address can not be empty",
                //     },
                // ],
                // businessAddressState: [
                //     {
                //         require: true,
                //         message: "Business Address can not be empty",
                //     },
                // ],
                businessLicenseNo: [
                    {
                        require: true,
                        message: "Business License No. can not be empty",
                    },
                ],
                businessType: [
                    {
                        require: true,
                        message: "Business Type can not be empty",
                    },
                ],
                dateEstablished: [
                    {
                        require: true,
                        message: "Date Established can not be empty",
                    },
                ],
                ownershipStructure: [
                    {
                        require: true,
                        message: "Ownership Structure can not be empty",
                    },
                ],
                "contact.contactSex": [
                    {
                        require: true,
                        message: "Contact Person can not be empty",
                    },
                ],
                "contact.contactName": [
                    {
                        require: true,
                        message: "Contact Person can not be empty",
                    },
                ],
                "contact.position": [
                    {
                        require: true,
                        message: "Position can not be empty",
                    },
                ],
                "contact.emailAddress": [
                    {
                        require: true,
                        message: "Email Address can not be empty",
                    },
                ],
                "contact.mobileNumber": [
                    {
                        require: true,
                        message: "Mobile Number can not be empty",
                    },
                ],
            },
            factoryRules: {
                factoryName: [
                    {
                        require: true,
                        message: "Factory Name can not be empty",
                    },
                ],
                factoryAddress: [
                    {
                        require: true,
                        message: "Factory Address can not be empty",
                    },
                ],
                telNo: [
                    {
                        require: true,
                        message: "Tel No. can not be empty",
                    },
                ],
                email: [
                    {
                        require: true,
                        message: "Email can not be empty",
                    },
                ],
                loadingPortLocationCity: [
                    {
                        require: true,
                        message: "Loading Port Location can not be empty",
                    },
                ],
                // loadingPortLocationCountry: [
                //     {
                //         require: true,
                //         message: "Loading Port Location can not be empty",
                //     },
                // ],
                // loadingPortLocationArea: [
                //     {
                //         require: true,
                //         message: "Loading Port Location can not be empty",
                //     },
                // ],
                exportPort: [
                    {
                        require: true,
                        message: "Export Port can not be empty",
                    },
                ],
                csr: [
                    {
                        require: true,
                        message: "CSR can not be empty",
                    },
                ],
            },
        };
    },
    created() {
        let data = JSON.parse(localStorage.getItem("dataList"));

        this.company = data.stepOneParam.company;

        this.dataStatus = this.company.dataStatus;

        let str = this.company.ownershipStructure;

        if (typeof str == "string") {
            this.company.ownershipStructure = str.split(",");
        }
        this.isNeedStepTwo = data.isNeedStepTwo;
        this.dataList = data.stepOneParam.factoryList;
        this.dataList.forEach((os) => {
            if (typeof os.ownershipStructure == "string") {
                os.ownershipStructure = os.ownershipStructure.split(",");
            }
            if (os.businessScaleList.length == 0) {
                os.businessScaleList = data1.businessScaleList;
            }
            os.businessScaleList[0].aliasName = "USD";
            os.businessScaleList[1].aliasName = "Volume (no. of TEU’s)";
            //   os.factoryName = "Unnamed " + (i + 1);
            this.checkList2.push({
                list: [],
            });
            this.tabLen.push({
                name: os.factoryName,
            });
        });
        this.businessList = JSON.parse(localStorage.getItem("business_type"));
        this.ownershipList = JSON.parse(
            localStorage.getItem("ownership_structure")
        );
        this.sexList = JSON.parse(localStorage.getItem("sex"));
        this.exportList = JSON.parse(localStorage.getItem("export_port"));
    },
    computed: {
        tabList() {
            return this.dataList.map((items, i) => {
                return {
                    name: items.factoryName
                        ? items.factoryName
                        : "Unnamed " + (i + 1),
                };
            });
        },
    },
    methods: {
        change(e) {
            this.curTab = e;
            this.$emit("update:activeIndex", e);
        },
        changeTab(tabs, type, index, index2) {
            if (type == "add") {
                data2.ownershipStructure = [];
                this.dataList.push(data2);
            } else {
                this.dataList.splice(index2, 1);
            }
        },
        dropEvent(val, item) {
            item.attachmentList.push(val);
        },
        dropDel(index, item) {
            item.attachmentList.splice(index, 1);
        },
        changeScope(val, item) {
            item.productScopeList = val;
        },
        changeMarket(val, item) {
            item.keymarketCustomerList = val;
        },
        changeInfoFile(val, item) {
            item.accreditationList = val;
        },
        changeAddress(e, type, item) {
            if (type == "Company") {
                item.businessAddressCity = e.City;
                item.businessAddressCountry = e.Country;
                item.businessAddressState = e.Detail;
            }
            if (type == "Factory" && item) {
                item.loadingPortLocationArea = e.Detail;
                item.loadingPortLocationCity = e.City;
                item.loadingPortLocationCountry = e.Country;
            } else {
                this.dataList.map((os) => {
                    os.loadingPortLocationArea = e.Detail;
                    os.loadingPortLocationCity = e.City;
                    os.loadingPortLocationCountry = e.Country;
                });
            }
        },
        validate(rules, params) {
            const keys = Object.keys(rules);
            console.log(rules);
            for (let i = 0; i < keys.length; i++) {
                const [key, key1, key2] = keys[i].split(".");

                const length = keys[i].split(".").length;

                let result;
                switch (length) {
                    case 1:
                        result = params[key];
                        break;
                    case 2:
                        if (Reflect.has(params, key)) {
                            result = params[key][key1];
                        }
                        break;
                    case 3:
                        if (
                            Reflect.has(params, key) &&
                            Reflect.has(params[key], key1)
                        ) {
                            result = params[key][key1][key2];
                        }
                        break;
                }

                const ruless = rules[keys[i]];

                for (let j = 0; j < ruless.length; j++) {
                    const rule = ruless[j];

                    if (rule.require && !result) {
                        this.$message({
                            message: rule.message,
                            type: "error",
                        });
                        return false;
                    }
                }
            }
            return true;
        },
        Submit() {
            if (this.request) return;
            const company = this.$u.filterId(this.company, ["attachmentList"]);
            let dataList = this.$u.filterId(this.dataList, [
                "attachmentList",
                "productScopeList",
                "keymarketCustomerList",
                "attachmentList",
            ]);
            console.log(dataList);
            company.ownershipStructure = Array.isArray(
                company.ownershipStructure
            )
                ? company.ownershipStructure.join(",")
                : "";
            dataList = dataList.map((items) => {
                items.ownershipStructure = Array.isArray(
                    items.ownershipStructure
                )
                    ? items.ownershipStructure.join(",")
                    : "";
                return items;
            });
            let parmas = {
                company: company,
                factoryList: dataList,
            };

            if (!this.validate(this.rules, company)) return;

            for (let i = 0; i < dataList.length; i++) {
                if (!this.validate(this.factoryRules, dataList[i])) return;
            }

            this.request = true;
            this.$http
                .submitStep1(parmas)
                .then((res) => {
                    this.request = false;
                    if (res.code == 200) {
                        this.$message({
                            message: res.data,
                            type: "success",
                        });
                        this.$http
                            .getDataByEmail({
                                email: company.contact.emailAddress,
                            })
                            .then((res) => {
                                const { data } = res;
                                localStorage.setItem(
                                    "dataList",
                                    JSON.stringify(data)
                                );
                            });
                    }
                })
                .catch(() => {
                    this.request = false;
                });
        },
    },
};
</script>

<style></style>
